import React, { useState } from "react"
import Confetti from 'react-confetti'
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import { TwitterShareButton, TwitterIcon } from "react-share";
import defaultImage from "../../../images/candyrarerandom.gif"

type SuccessProps = {}

const Success: React.FC<SuccessProps> = ({}) => {
  const { innerWidth: width, innerHeight: height } = window;
  const [shouldRecycleConfetti, setShouldRecycleConfetti] = useState<boolean>(true)
  setTimeout(() => {
    setShouldRecycleConfetti(false);
  }, 10000);

  return (
    <>
      <Confetti
        width={width}
        height={height}
        gravity={0.1}
        recycle={shouldRecycleConfetti}
      />
      <Container>
        <div className="centered" style={{ marginBottom: "20px" }}>
          <Row>
            <Col>
              <h1>🎉 Congratulations on your purchase! 🎉</h1>
              <Image src={defaultImage} style={{ width: "400px" }} />
            </Col>
          </Row>

          <Row style={{ marginBottom: "1.45rem" }}>
            <Col>
              <p>Click the button below to view your CryptoCandies!</p>
              <Button
                variant="outline-primary"
                className="candy-button"
                href="/mycandies"
              >
                View My Candies
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <p>Share your CryptoCandy!</p>
              <p>
                <TwitterShareButton
                  url="https://www.cryptocandies.com"
                  title="🍬 I just picked up some treats from @CryptoCandies_! 🍬"
                >
                  <TwitterIcon
                    size={32}
                    round
                  />
                </TwitterShareButton>
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Success
